import { cn } from "@nephroflow/design-system/styling/utils";

import { Logo } from "./logo";

export function Loading({ className }: { className?: string }) {
  return (
    <div className={cn("flex h-full items-center justify-center", className)}>
      <Logo className="h-12 w-12 animate-pulse" />
    </div>
  );
}
