import { z } from "zod";

import { DeviceSchema, TenantSchema } from "~/services/api-schemas";
import { http } from "~/services/http";

import { ClientFormState } from "./client-action-form";
import { DeviceFormState } from "./device-action-form";

export async function fetchDevice(deviceId: string) {
  const { data } = await http.get({
    path: `devices/${deviceId}`,
    schema: z.object({ device: DeviceSchema }),
  });

  return data.device;
}

export async function linkUpdateDevice({
  deviceId,
  deviceDetails,
}: {
  deviceId: string;
  deviceDetails: DeviceFormState;
}) {
  const { data } = await http.put({
    path: `devices/${deviceId}`,
    schema: z.object({ device: DeviceSchema }),
    body: {
      device: deviceDetails,
    },
  });

  return data.device;
}

export async function fetchTenants() {
  const { data } = await http.get({
    path: "tenants",
    schema: z.object({ data: z.array(TenantSchema) }),
  });

  return data.data;
}

export async function fetchTenant(tenantId: string) {
  const { data } = await http.get({
    path: `tenants/${tenantId}`,
    schema: z.object({ tenant: TenantSchema }),
  });

  return data.tenant;
}

export async function deleteTenant(tenantId: string) {
  await http.delete({
    path: `tenants/${tenantId}`,
  });
}

export async function updateTenant({ tenantId, tenantDetails }: { tenantId: string; tenantDetails: ClientFormState }) {
  const { data } = await http.put({
    path: `tenants/${tenantId}`,
    schema: z.object({ tenant: TenantSchema }),
    body: tenantDetails,
  });

  return data.tenant;
}

export async function addTenant({ tenantDetails }: { tenantDetails: ClientFormState }) {
  const { data } = await http.post({
    path: `tenants`,
    schema: z.object({ tenant: TenantSchema }),
    body: tenantDetails,
  });

  return data.tenant;
}
