export function Logo({ className }: { className?: string }) {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 218 184" width="32" height="32">
      <mask id="a" width="218" height="184" x="0" y="0" maskUnits="userSpaceOnUse">
        <path fill="#fff" d="M0 0h217.31v183.619H0V0Z" />
      </mask>
      <g mask="url(#a)">
        <path
          fill="#004397"
          d="M0 127.652V45.824C0 19.329 22.58.34 42.855.34c20.294 0 30.756 8.185 38.618 17.333 7.85 9.165 102.44 121.432 102.44 121.432l2.61 3.09c4.896 5.801 13.571 6.54 19.373 1.64 5.801-4.896 6.538-13.569 1.641-19.37l-.946-1.118-68.145-80.8c-8.502-10.466-24.568-5.874-24.568 6.89 0 0-4.3-5.102-4.565-5.412C109.713 7.436 136.901 0 151.18 0c14.39 0 21.28 3.278 33.711 17.673 12.444 14.408 32.42 38.944 32.42 38.944v82.488c0 26.184-20.932 44.514-42.537 44.514-21.612 0-30.454-7.528-38.31-16.699-7.76-9.06-96.795-114.408-106.073-125.654l-.12-.15c-4.806-5.693-13.317-6.414-19.012-1.61-5.695 4.804-6.415 13.315-1.61 19.015l.184.211 72.293 85.612c8.245 6.98 22.948 1.051 23.26-7.65.412.481 4.244 5.025 4.244 5.025 0 22.58-15.72 41.9-44.186 41.9-16.382 0-24.216-7.52-32.079-16.686C25.502 157.761 0 127.652 0 127.652Z"
        />
      </g>
    </svg>
  );
}
